import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAINTENANCE_CHECK_BASE_URL } from '@campus/browser';
import { ENVIRONMENT_V2_ROUTES } from '@campus/environment';
import {
  AuthService,
  AUTH_SERVICE,
  GRECAPTCHA_TOKEN,
  PersonApi,
  PORTAL_ENVIRONMENT_TOKEN,
} from '@campus/public-portal';
import { environment } from '../environments/environment';
import { PersistedUsersService } from './services/persisted-users.service';

@NgModule({
  providers: [
    PersistedUsersService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: PersonApi) => () => authService.getCurrent().toPromise(),
      multi: true,
      deps: [PersonApi],
    },
    {
      provide: AUTH_SERVICE,
      useClass: AuthService,
    },
    {
      provide: PORTAL_ENVIRONMENT_TOKEN,
      useValue: environment,
    },
    {
      provide: MAINTENANCE_CHECK_BASE_URL,
      useValue: '/',
    },
    {
      provide: GRECAPTCHA_TOKEN,
      useFactory: () => {
        return window['grecaptcha'] as ReCaptchaV2.ReCaptcha; // google recaptcha script is loaded in index.html
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: ENVIRONMENT_V2_ROUTES, useValue: false },
  ],
})
export class AppTokenModule {}
